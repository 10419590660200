import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';

export function useSentry() {
  const sentryInitialized = useRef(false);
  useEffect(() => {
    if (sentryInitialized.current === false) {
      Sentry.init({
        dsn: 'https://314091e85aa8dc467a3dae39d019c1c7@o1021155.ingest.sentry.io/4506146039005184',
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost']
          })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0 // Capture 100% of the transactions
      });
      sentryInitialized.current = true;
    }
  }, []);
}
